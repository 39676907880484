import React from 'react';
import './SingleTestimonial.scss';
import star from '../../../images/Star.png';

type Props = {
  imageSrc: string;
  name: string;
  testimonial: string;
  starNumber: number;
};

function SingleTestimonial({ imageSrc, name, testimonial, starNumber }: Props) {
  return (
    <div className='carousel-inner-container'>
      <div className='carousel-wrapper'>
        <div className='row carousel-content'>
          <div className='col-12 col-sm-12 col-md-12 col-lg-3'>
            <div className='d-flex justify-content-center testimonial-picture-wrapper'>
              <img
                className='testimonial-picture d-flex justify-content-center'
                src={imageSrc}
                alt='profile'
              />
            </div>
            <div className='star-ratings'>
              <div className='row ds-flex justify-content-center'>
                <div className='col-2 rating-picture'>
                  <img src={star} alt='star' />
                </div>
                <div className='col-2'>
                  <img src={star} alt='star' />
                </div>
                <div className='col-2'>
                  <img src={star} alt='star' />
                </div>
                <div className='col-2'>
                  <img src={star} alt='star' />
                </div>
                <div className='col-2'>
                  <img src={star} alt='star' />
                </div>
              </div>
            </div>
          </div>
          <div className='col-12 col-sm-12 col-md-12 col-lg-9'>
            <p className='p-testimonial-font'>"{testimonial}"</p>
            <p className='signature-font d-flex flex-row-reverse'>{name}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SingleTestimonial;
