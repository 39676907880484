import React from 'react';

import ParagraphHeading from '../../paragraphHeading/ParagraphHeading';
import GalleryCarousel from '../../../components/galleryCarousel/GalleryCarousel';

import images from '../../../theme/images';
import './galleryCarousel/GalleryCarousel.scss';

function Gallery() {
  return (
    <div id='galerie' className='galleryDiv'>
      <ParagraphHeading headingImg={images.gallery} />
      <GalleryCarousel />
    </div>
  );
}

export default Gallery;
