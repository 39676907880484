import React from 'react';
import { Routes } from 'react-router-dom';
import { BrowserRouter, Route } from 'react-router-dom';

import LandingPage from '../../../screens/LandingPage';
import ThankYouPage from '../../thankYouModal/ThankYouPage';

const Navigation = () => {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<LandingPage />} />
          <Route path='/thankYou' element={<ThankYouPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default Navigation;
