import React from 'react';

type Props = {
  text: string | undefined;
  link: string | undefined;
  extraClasses?: string;
  onClick?: () => void;
};

function ButtonMainColorBg({ text, link, extraClasses, onClick }: Props) {
  return (
    <div>
      <a
        onClick={onClick}
        className={
          'button-main-color p-white-font-big d-flex justify-content-center ' +
          ' ' +
          extraClasses
        }
        href={link}
      >
        {text}
      </a>
    </div>
  );
}

export default ButtonMainColorBg;
