const strings = {
  aboutSection: {
    heading: 'Despre',
    italicHeading: 'noi',
    about:
      'Romantic Studio Brașov este proiectul nostru de suflet și făcut din suflet pentru voi. Este o oază de liniște, un loc elegant și confortabil, creat special pentru cuplurile care doresc să petreacă un timp relaxant într-o atmosferă romantică. Apartamentul este compus dintr-o cameră spațioasă cu piesa lui de rezistență, cadă cu hidromasaj, care vă va oferi momente intime și relaxante. Ca bonus, priveliștea panoramică deosebită o să vă încânte diminețile și timpul petrecut în doi.',
    textLeft:
      'Romantic Studio Brașov este proiectul nostru de suflet și făcut din suflet pentru voi. Este o oază de liniște, un loc elegant și confortabil, creat special pentru cuplurile care doresc să petreacă un timp relaxant într-o atmosferă romantică.',
    textRight:
      'Apartamentul este compus dintr-o cameră spațioasă cu piesa lui de rezistență, cadă cu hidromasaj, care vă va oferi momente intime și relaxante. Ca bonus, priveliștea panoramică deosebită o să vă încânte diminețile și timpul petrecut în doi.',
  },

  iconSection: {
    panoramaHeading: 'Vedere panoramică',
    panoramaDesc:
      'Clipele petrecute în jacuzzi, diminețile târzii și apusurile sunt completate de priveliștea deosebită și liniștitoare către masivul Tâmpa.',
    panoramaAlt: 'icon panoramă',
    houseHeading: 'Atmosfera caldă și romantică',
    houseDesc:
      'Baldachinul suspendat, lumina ambientală și elementele de design atent alese vă introduc într-o atmosferă relaxantă și romantică, potrivită pentru timpul petrecut în doi.',
    houseAlt: 'icon casă cu inimă',
    bathHeading: 'Cadă cu hidromasaj',
    bathDesc:
      'Atracția apartamentului și locul unde veți avea parte de cele mai liniștitoare momente este cada cu hidromasaj.',
    bathAlt: 'icon cadă cu hidromasaj',
  },
  testimonialSection: {
    heading: 'Recenzii',
  },
  gallerySection: {
    heading: 'Galerie',
  },
  mapSection: {
    heading: 'Localizare',
    keysAlt: {
      tree: 'icon brad',
      star: 'icon stea',
      cart: 'icon coș cumpărături',
      medical: 'icon spital',
    },
    keysTitle: {
      tree: 'Parcuri & Relaxare',
      star: 'Divertisment',
      cart: 'Spații Comerciale',
      medical: 'Medical',
    },
  },
  form: {
    name: 'Nume',
    telephone: 'Telefon',
    message: 'Mesaj',
    email: 'Email',
    button: 'Trimite mesajul',
    messagePlaceholder: 'Vă rugăm includeți și data cazării',
    success:
      'Mesajul dvs a fost trimis cu succes! Veți fi contactat în cel mai scurt timp',
    error:
      'Mesajul nu a fost trimis. Vă rugăm încercați încă odată. Dacă problema persistă, vă rugăm să ne contactați telefonic.',
    checkbox: 'Abonează-te și află cele mai noi oferte',
  },
  reserveHeading: 'Rezervă',
  reserveHeadingItalic: 'acum',
  bookingButtonAlt: 'buton rezervare booking.com',
  airbnbButtonAlt: 'buton rezervare airbnb',
  offers: {
    card1: {
      standardTitle: 'Preturi standard',
      standardDesc1: 'Weekend',
      standardDesc1Italic: '(vineri-duminică) - ',
      standardDesc1Price: '450 lei',
      standardDesc2: 'În timpul săptămânii',
      standardDesc2Italic: '(duminică-vineri)',
      standardDesc2Price: '350 lei',
      smallerText:
        '* La aceste prețuri este inclusă o surpriză din partea casei',
    },
    card2: {
      offersTitle: 'Oferte Speciale',
      descriptionLine1a: 'Pentru minim',
      nights1: '2 nopți',
      descriptionLine1b: 'este oferită o',
      offerAmount1: 'reducere de 10%',
      descriptionLine2a: 'Pentru',
      nights2: '5 nopți ',
      descriptionLine2b: 'este oferită o',
      smallerText: '(weekly stay)',
      offerAmount2: ' reducere de 20%',
    },
  },
  thankYouText: 'Mulțumim! Rezervarea ta a fost trimisă! ',
  thankYouText2:
    'În cel mai scurt timp vom verifica dacă data aleasă de tine este disponibilă și te vom contacta telefonic.',
  socialLinksText: 'Fii cu ochii pe noi și pe rețelele noastre de socializare',
  valentinesBannerText: 'Grăbește-te să prinzi oferta pentru luna iubirii',
  offerTextLink: 'Vezi oferta',
};

export default strings;
