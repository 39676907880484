import React from 'react';
import { Row, Col } from 'react-bootstrap';

import ParagraphHeading from '../paragraphHeading/ParagraphHeading';
import OfferCard from './OfferCard';
import PriceCard from './PriceCard';

import './styles.scss';
import strings from '../../theme/strings';
import images from '../../theme/images';

const Offers = () => {
  return (
    <div className='offersWrapper' id='oferte'>
      <ParagraphHeading headingImg={images.offers} />
      <Row>
        <Col xs={12} sm={12} md={6} xl={6}>
          <PriceCard
            offerImg={images.offer1}
            cardTitle={strings.offers.card1.standardTitle}
            descriptionLine1={strings.offers.card1.standardDesc1}
            descriptionLine1Italic={strings.offers.card1.standardDesc1Italic}
            descriptionLine1Price={strings.offers.card1.standardDesc1Price}
            descriptionLine2={strings.offers.card1.standardDesc2}
            descriptionLine2Italic={strings.offers.card1.standardDesc2Italic}
            descriptionLine2Price={strings.offers.card1.standardDesc2Price}
            descriptionLine3={strings.offers.card1.smallerText}
          />
        </Col>
        <Col xs={12} sm={12} md={6} xl={6}>
          <OfferCard
            hideButton={true}
            offerImg={images.offer2}
            className='cardImg'
            cardStyle='cardStyle'
            cardTitle={strings.offers.card2.offersTitle}
            descriptionLine1a={strings.offers.card2.descriptionLine1a}
            nights1={strings.offers.card2.nights1}
            descriptionLine1b={strings.offers.card2.descriptionLine1b}
            offerAmount1={strings.offers.card2.offerAmount1}
            descriptionLine2a={strings.offers.card2.descriptionLine2a}
            nights2={strings.offers.card2.nights2}
            descriptionLine2b={strings.offers.card2.descriptionLine2b}
            smallerText={strings.offers.card2.smallerText}
            offerAmount2={strings.offers.card2.offerAmount2}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Offers;
