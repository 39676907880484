import React from 'react';
import nmcpLogo from '../../../images/ps1 1.png';
import './Copyrights.scss';

function Copyrights() {
  return (
    <div>
      <div className='row boxed-content-wide'>
        <div className='col-12 col-sm-6 col-md-8 col-lg-6 p-white-thin d-flex justify-content-end align-items-center'>
          <p className='copyrights'>
            Copyright © 2021 | Romantic Studio | Toate drepturile rezervate.
          </p>
        </div>
        <div className='col-12 col-sm-6 col-md-4 col-lg-6 p-white-thin d-flex justify-content-center'>
          <p className='copyrights'>
            Designed and powered by{' '}
            <a
              href='https://www.nomorecoffeeplease.com/'
              target='_blank'
              rel='noreferrer'
            >
              <img src={nmcpLogo} alt='logo' />
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Copyrights;
