import React, { useEffect } from 'react';

import ReactGA from 'react-ga';
import CookieConsent, {
  Cookies,
  getCookieConsentValue,
} from 'react-cookie-consent';

import LandingPage from './screens/LandingPage';

import conversionsApi from './utils/conversionApi';

import './App.scss';
import Navigation from './components/reusableComponents/Menu/Navigation';

require('dotenv').config();

const googleAnalyticsId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;

ReactGA.initialize(googleAnalyticsId ? googleAnalyticsId : '');

function App() {
  useEffect(() => {
    conversionsApi();
    ReactGA.pageview('/');
    window.addEventListener('hashchange', (e) => {
      conversionsApi();
      ReactGA.pageview(window.location.hash);
    });
  }, []);

  // console.log(getCookieConsentValue());
  return (
    <div>
      <Navigation />
    </div>
  );
}

export default App;
