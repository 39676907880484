import React from 'react';

import ResponsiveMenu from '../../reusableComponents/Menu/ResponsiveMenu';
import TitleDescButton from '../../reusableComponents/TitleDescButton/TitleDescButton';

import './FirstSection.scss';

function FirstSection() {
  return (
    <div
      id="#acasa"
      className="first-section-background d-flex align-items-center"
    >
      <ResponsiveMenu />

      <TitleDescButton
        heading={'Romantic Studio Apartment'}
        description={'Timp pentru voi, timp în doi'}
        text={'Rezervă acum'}
        link={'#rezervari'}
      />
    </div>
  );
}

export default FirstSection;
