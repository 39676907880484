import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import images from '../../theme/images';

import strings from '../../theme/strings';
import SocialLinks from '../reusableComponents/Footer/SocialLinks';

const ThankYouPage = () => {
  return (
    <div>
      <Card className='thankYouCard'>
        <Card.Body>
          <Card.Title>
            <h4>{strings.thankYouText}</h4>
            <br />
            <p>{strings.thankYouText2}</p>
            <br />
            <p>{strings.socialLinksText}</p>
          </Card.Title>
        </Card.Body>
        <div className='sm-cionDark'>
          <SocialLinks
            fbIcon={images.fbIconDark}
            igIcon={images.igIconDark}
            airBnBIcon={images.airBnbIconDark}
            bookingBnBIcon={images.bookingIconDark}
          />
        </div>
      </Card>
    </div>
  );
};

export default ThankYouPage;
