import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { Row, Col } from 'react-bootstrap';
import images from '../../theme/images';
import GalleryCard from '../reusableComponents/gallery/galleryCard/GalleryCard';
import './styles.scss';

import { GalleryPhotos as galleryImages } from '../../constants/gallery/GalleryPhotos';

const GalleryCarousel = () => {
  return (
    <div className='galleryContainer'>
      <Carousel>
        {galleryImages.map((galleryPhotos: any) => (
          <Carousel.Item>
            <div className='galleryCard'>
              <GalleryCard
                portraitPhoto={galleryPhotos.portraitPhoto}
                widePhoto1={galleryPhotos.widePhoto1}
                widePhoto2={galleryPhotos.widePhoto2}
                portraitPhoto2={galleryPhotos.portraitPhoto2}
              />
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

export default GalleryCarousel;
