import React from 'react';
import images from '../../theme/images';

import './horizontalLine.scss';

const HorizontalLine = () => {
  return (
    <div className='lineWrapper'>
      <img className='line' src={images.horizontalLine} alt='line' />
    </div>
  );
};

export default HorizontalLine;
