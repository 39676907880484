import React from 'react';

import ButtonMainColorBg from '../Button/ButtonMainColorBG/ButtonMainColorBg';

type Props = {
  heading: string;
  description: string;
  link: string;
  text: string;
};

function TitleDescButton({ heading, description, link, text }: Props) {
  return (
    <div className='title-desc-wrapper'>
      <div className='d-flex justify-content-start boxed-content-wide '>
        <div>
          <h2 className='d-flex justify-content-start white-big-heading'>
            {heading}
          </h2>
          <p className='d-flex justify-content-start italic-desc-big'>
            {description}
          </p>
          <ButtonMainColorBg link={link} text={text} extraClasses={''} />
        </div>
      </div>
    </div>
  );
}

export default TitleDescButton;
