import React from 'react';
import { Card } from 'react-bootstrap';
import HorizontalLine from '../horizontalLine/HorizontalLine';

interface IProps {
  offerImg: string;
  cardTitle: string;
  descriptionLine1: string;
  descriptionLine1Italic: string;
  descriptionLine1Price: string;
  descriptionLine2: string;
  descriptionLine2Italic: string;
  descriptionLine2Price: string;
  descriptionLine3: string | null;
}

const PriceCard = (props: IProps) => {
  return (
    <div>
      <Card className='cardStyle'>
        <Card.Img className='cardImg' variant='top' src={props.offerImg} />
        <Card.Body>
          <Card.Title className='cardTitle'>{props.cardTitle}</Card.Title>
          <Card.Text>
            <p className='description'>
              {props.descriptionLine1}{' '}
              <span className='smallerDescription'>
                {props.descriptionLine1Italic}
              </span>{' '}
              <span className='boldText'>{props.descriptionLine1Price}</span>
            </p>
          </Card.Text>
          <Card.Text>
            <p className='description'>
              {props.descriptionLine2}{' '}
              <span className='smallerDescription'>
                {props.descriptionLine2Italic}
              </span>{' '}
              <span className='boldText'>{props.descriptionLine2Price}</span>
            </p>
          </Card.Text>
          <p className='smallText'>{props.descriptionLine3}</p>
        </Card.Body>
        <HorizontalLine />
      </Card>
    </div>
  );
};

export default PriceCard;
