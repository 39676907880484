import React from 'react';
import ReactDOM from 'react-dom';
import ReactPixel from 'react-facebook-pixel';

import App from './App';

import './index.scss';

const advancedMatching = {
  em: '',
  ct: '',
  country: '',
  db: '',
  fn: '',
  ge: '',
  ln: '',
  ph: '',
  st: '',
  zp: '',
};

ReactPixel.init('2702935380000948', advancedMatching, {
  debug: true,
  autoConfig: false,
});
ReactPixel.pageView();
ReactPixel.fbq('track', 'PageView', 'booking.com', 'airbnb');

ReactPixel.pageView();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
