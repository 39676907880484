import React from 'react';

import './ParagraphHeading.scss';

interface IProps {
  headingImg: string;
}

const ParagraphHeading = (props: IProps) => {
  return (
    <>
      <img className='headingImg' src={props.headingImg} alt='heading' />
    </>
  );
};

export default ParagraphHeading;
