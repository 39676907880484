import React from 'react';
import { Row, Col } from 'react-bootstrap';
import ReactPixel from 'react-facebook-pixel';

import ParagraphHeading from '../paragraphHeading/ParagraphHeading';
import ContactForm from './ContactForm';
import ReserveButton from './ReserveButton';

import images from '../../theme/images';
import strings from '../../theme/strings';
import './reserveSection.scss';

const ReserveSection = () => {
  return (
    <div id='rezervari'>
      <div className='formContainer'>
        <ParagraphHeading headingImg={images.rezerve} />
        <ContactForm />
      </div>
      <div className='btnWrapper'>
        <Row className='d-flex'>
          <Col
            className='d-flex justify-content-center'
            xs={12}
            sm={12}
            md={12}
            lg={6}
          >
            <ReserveButton
              src={images.bookingButton}
              alt={strings.bookingButtonAlt}
              btnStyle='reserveBtnLeft d-flex justify-content-center'
              url='https://www.booking.com/hotel/ro/romantic-studio.en-gb.html'
              onClick={() => ReactPixel.fbq('track', 'booking.com')}
            />
          </Col>
          <Col
            className='d-flex justify-content-center'
            xs={12}
            sm={12}
            md={12}
            lg={6}
          >
            <ReserveButton
              src={images.airbnbButton}
              alt={strings.airbnbButtonAlt}
              btnStyle='reserveBtnRight d-flex justify-content-center'
              url='https://www.airbnb.com/rooms/51572270'
              onClick={() => ReactPixel.fbq('track', 'airbnb')}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ReserveSection;
