import React from 'react';
import SideDrawer from './responsivemenu/sidemenu/SideDrawer';
import Toolbar from './responsivemenu/ToolBar';
import Backdrop from './responsivemenu/backdrop/BackDrop';
import './Menu.scss';

class ResponsiveMenu extends React.Component {
  state = {
    sideDrawerOpen: false,
  };

  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };
  backDropClickHandler = () => {
    this.setState({ sideDrawerOpen: false });
  };

  render() {
    // let sideDrawer;
    let backdrop;

    if (this.state.sideDrawerOpen) {
      // sideDrawer = <SideDrawer />;
      backdrop = <Backdrop click={this.backDropClickHandler} />;
    }
    return (
      <div>
        <Toolbar drawerToggleClickHandler={this.drawerToggleClickHandler} />
        <SideDrawer show={this.state.sideDrawerOpen} />
        {backdrop}
      </div>
    );
  }
}

export default ResponsiveMenu;
