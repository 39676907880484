import React from 'react';
import { Col, Row } from 'react-bootstrap';
import ParagraphHeading from '../paragraphHeading/ParagraphHeading';
import Paragraph from '../paragraph/Paragraph';
import strings from '../../theme/strings';
import images from '../../theme/images';
import './aboutSection.scss';

const AboutSection = () => {
  return (
    <div id='despre-noi'>
      <div className='paragraphCard'>
        <div>
          <Row>
            <Col xs={12} sm={12} md={12} xl={6}>
              <img className='paragraphImg' src={images.about} alt='about us' />
            </Col>
            <Col xs={12} sm={12} md={12} xl={6}>
              <ParagraphHeading headingImg={images.aboutUs} />
              <Paragraph text={strings.aboutSection.about} />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
