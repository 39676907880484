import React from 'react';
import { Card } from 'react-bootstrap';

import HorizontalLine from '../horizontalLine/HorizontalLine';
import ButtonMainColorBg from '../reusableComponents/Button/ButtonMainColorBG/ButtonMainColorBg';

interface IProps {
  offerImg: string;
  className: string;
  cardStyle: string;
  cardTitle?: string;
  descriptionLine1a?: string;
  descriptionLine1b?: string;
  nights1?: string;
  offerAmount1?: string;
  descriptionLine2a?: string;
  smallerText?: string;
  descriptionLine2b?: string;
  nights2?: string;
  offerAmount2?: string;
  hideHorizontalLine?: boolean;
  hideButton?: boolean;
  onClick?: () => void;
  btnLink?: string;
  btnText?: string;
  text?: string;
  link?: string;
  extraClasses?: string;
  btnStyle?: string;
}

const OfferCard = (props: IProps) => {
  return (
    <div>
      <Card className={props.cardStyle}>
        <Card.Img
          className={props.className}
          variant='top'
          src={props.offerImg}
        />
        {!props.hideButton && (
          <ButtonMainColorBg
            link={props.btnLink}
            text={props.btnText}
            extraClasses={props.btnStyle}
            onClick={props.onClick}
          />
        )}
        <Card.Body>
          <Card.Title className='cardTitle'>{props.cardTitle}</Card.Title>
          <Card.Text>
            <p className='description'>
              {props.descriptionLine1a}{' '}
              <span className='boldText'>{props.nights1}</span>{' '}
              {props.descriptionLine1b}{' '}
              <span className='boldText'>{props.offerAmount1}</span>
            </p>
          </Card.Text>
          <Card.Text>
            <p className='description'>
              {props.descriptionLine2a}{' '}
              <span className='boldText'>{props.nights2}</span>
              <span className='smallerDescription'>
                {props.smallerText}
              </span>{' '}
              {props.descriptionLine2b}
              <span className='boldText'>{props.offerAmount2}</span>
            </p>
          </Card.Text>
        </Card.Body>
        {!props.hideHorizontalLine && <HorizontalLine />}
      </Card>
    </div>
  );
};

export default OfferCard;
