import images from '../theme/images';
import strings from '../theme/strings';

export type placesType = {
  id: number;
  type: string;
  name: string;
  address: string;
  coordinates: number[];
  icon: string;
  alt?: string;
  category?: string;
};

export const places = {
  features: [
    {
      id: 1,
      type: 'park',
      name: 'Schaeffler - Livada Poștei Park',
      address: 'Șirul Livezii',
      coordinates: [45.64678068960319, 25.584974906241605],
      icon: images.treeIcon,
      alt: strings.mapSection.keysAlt.tree,
      category: strings.mapSection.keysTitle.tree,
    },
    {
      id: 2,
      type: 'park',
      name: 'Parcul Nicolae Titulescu',
      address: 'B-dul Eroilor',
      coordinates: [45.64597065005195, 25.593042990977523],
      icon: images.treeIcon,
      alt: strings.mapSection.keysAlt.tree,
      category: strings.mapSection.keysTitle.tree,
    },
    {
      id: 3,
      type: 'park',
      name: 'Piața Consiliul Europei',
      address: 'Strada Mihail Kogălniceanu nr. 18',
      coordinates: [45.65215821981449, 25.61081620641424],
      icon: images.treeIcon,
      alt: strings.mapSection.keysAlt.tree,
      category: strings.mapSection.keysTitle.tree,
    },
    {
      id: 4,
      type: 'park',
      name: 'Parcul Sportiv Tractorul',
      address: 'Str. Turnului',
      coordinates: [45.664051437898, 25.610171334195442],
      icon: images.treeIcon,
      alt: strings.mapSection.keysAlt.tree,
      category: strings.mapSection.keysTitle.tree,
    },
    {
      id: 5,
      type: 'fun',
      name: 'Paradisul Acvatic',
      address: 'Bulevardul Griviței nr. 2F',
      coordinates: [45.673641046353055, 25.58608335596917],
      icon: images.starIcon,
      alt: strings.mapSection.keysAlt.star,
      category: strings.mapSection.keysTitle.star,
    },
    {
      id: 6,
      type: 'fun',
      name: 'Splash Paintball Brașov',
      address: 'Str. Ecaterina Teodoroiu',
      coordinates: [45.669046084448176, 25.579318140626214],
      icon: images.starIcon,
      alt: strings.mapSection.keysAlt.star,
      category: strings.mapSection.keysTitle.star,
    },
    {
      id: 7,
      type: 'fun',
      name: 'Piața Sfatului',
      address: 'Piața Sfatului, Centrul Vechi',
      coordinates: [45.64232216782332, 25.588939373433494],
      icon: images.starIcon,
      alt: strings.mapSection.keysAlt.star,
      category: strings.mapSection.keysTitle.star,
    },
    {
      id: 8,
      type: 'fun',
      name: 'Escape Rooms',
      address: 'Str. Republicii nr. 58',
      coordinates: [45.64388689185833, 25.593877727132732],
      icon: images.starIcon,
      alt: strings.mapSection.keysAlt.star,
      category: strings.mapSection.keysTitle.star,
    },
    {
      id: 9,
      type: 'shops',
      name: 'Coresi Shopping Center',
      address: 'Str. Zaharia Stancu nr. 1',
      coordinates: [45.673551800903724, 25.61890810185609],
      icon: images.cartIcon,
      alt: strings.mapSection.keysAlt.cart,
      category: strings.mapSection.keysTitle.cart,
    },
    {
      id: 10,
      type: 'shops',
      name: 'Unirea Shopping Center',
      address: 'B-dul Gării nr. 3A',
      coordinates: [45.66143231028802, 25.610906583880443],
      icon: images.cartIcon,
      alt: strings.mapSection.keysAlt.cart,
      category: strings.mapSection.keysTitle.cart,
    },
    {
      id: 11,
      type: 'shops',
      name: 'Profi',
      address: 'B-dul Victoriei nr. 2',
      coordinates: [45.65943867237575, 25.61360735383068],
      icon: images.cartIcon,
      alt: strings.mapSection.keysAlt.cart,
      category: strings.mapSection.keysTitle.cart,
    },
    {
      id: 12,
      type: 'shops',
      name: 'Piața Dacia',
      address: 'Str. Prahova',
      coordinates: [45.65886601857544, 25.611452807016445],
      icon: images.cartIcon,
      alt: strings.mapSection.keysAlt.cart,
      category: strings.mapSection.keysTitle.cart,
    },
    {
      id: 13,
      type: 'shops',
      name: 'Mega Image',
      address: 'Str. Zizinului nr. 65',
      coordinates: [45.65042399782354, 25.62535115146862],
      icon: images.cartIcon,
      alt: strings.mapSection.keysAlt.cart,
      category: strings.mapSection.keysTitle.cart,
    },
    {
      id: 14,
      type: 'shops',
      name: 'Kaufland',
      address: 'Str. Avram Iancu nr. 62',
      coordinates: [45.65861911933113, 25.589495357420777],
      icon: images.cartIcon,
      alt: strings.mapSection.keysAlt.cart,
      category: strings.mapSection.keysTitle.cart,
    },
    {
      id: 15,
      type: 'medical',
      name: 'Spitalul REGINA MARIA Private Healthcare',
      address: 'Str. Iuliu Maniu nr. 49',
      coordinates: [45.65097911703252, 25.606770110513473],
      icon: images.medicalIcon,
      alt: strings.mapSection.keysAlt.medical,
      category: strings.mapSection.keysTitle.medical,
    },
    {
      id: 16,
      type: 'medical',
      name: 'Spitalul Clinic Județean de Urgență',
      address: 'Calea București nr. 25',
      coordinates: [45.648819147444456, 25.620631766177176],
      icon: images.medicalIcon,
      alt: strings.mapSection.keysAlt.medical,
      category: strings.mapSection.keysTitle.medical,
    },
    {
      id: 17,
      type: 'medical',
      name: 'Farmacom - 98',
      address: 'B-dul Griviței nr. 67',
      coordinates: [45.65562876888892, 25.601834846490366],
      icon: images.medicalIcon,
      alt: strings.mapSection.keysAlt.medical,
      category: strings.mapSection.keysTitle.medical,
    },
    {
      id: 18,
      type: 'medical',
      name: 'Farmacia Catena',
      address: 'B-dul Gării nr. 38',
      coordinates: [45.6587580997018, 25.620667620172835],
      icon: images.medicalIcon,
      alt: strings.mapSection.keysAlt.medical,
      category: strings.mapSection.keysTitle.medical,
    },
    {
      id: 19,
      type: 'medical',
      name: 'Farmacia Richter',
      address: 'Str. 13 Decembrie nr. 115',
      coordinates: [45.664893015279894, 25.608827446632557],
      icon: images.medicalIcon,
      alt: strings.mapSection.keysAlt.medical,
      category: strings.mapSection.keysTitle.medical,
    },
  ],
};
