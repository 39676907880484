import React from 'react';
import './mapSection.scss';

interface IProps {
  keyIcon: string;
  alt: string;
  title: string;
}
const MapKey = (props: IProps) => {
  return (
    <div className='mapKeyWrapper'>
      <div className='keyIcon'>
        <img src={props.keyIcon} alt={props.alt} />
      </div>
      <h3 className='keyTitle'>{props.title}</h3>
    </div>
  );
};

export default MapKey;
