import React from 'react';
import { Row, Col } from 'react-bootstrap';
import ParagraphHeading from '../../components/paragraphHeading/ParagraphHeading';
import Map from './Map';
import MapKey from './MapKey';
import strings from '../../theme/strings';
import images from '../../theme/images';
import './mapSection.scss';

const MapSection = () => {
  return (
    <div id='localizare'>
      <div className='mapSectionWrapper'>
        <ParagraphHeading headingImg={images.location} />
        <Row>
          <Col xs={12} sm={12} md={12} lg={6}>
            <Map />
          </Col>
          <Col xs={12} sm={12} md={12} lg={6} className='mapKeys'>
            {Object.keys(strings.mapSection.keysTitle).map((key) => (
              <MapKey
                keyIcon={(images as any)[key + 'Icon']}
                alt={(strings as any).mapSection.keysAlt[key]}
                title={(strings as any).mapSection.keysTitle[key]}
              />
            ))}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default MapSection;
