import React from 'react';
import './SideDrawer.css';

const sideDrawer = (props) => {
  let drawerClasses = ['side-drawer'];

  if (props.show) {
    drawerClasses = ['side-drawer', 'open'];
  }
  return (
    <nav className={drawerClasses.join(' ')}>
      <ul className='nav'>
        <li className='nav-item gradient-underline-menu-item'>
          <a className='nav-link' href='#'>
            Acasă
          </a>
        </li>
        <li className='nav-item gradient-underline-menu-item'>
          <a className='nav-link' href='#despre-noi'>
            Despre Noi
          </a>
        </li>
        <li className='nav-item gradient-underline-menu-item'>
          <a className='nav-link' href='#galerie'>
            Galerie
          </a>
        </li>
        <li className='nav-item gradient-underline-menu-item'>
          <a className='nav-link' href='#recenzii'>
            Recenzii
          </a>
        </li>
        <li className='nav-item gradient-underline-menu-item'>
          <a className='nav-link' href='#rezervari'>
            Rezervări
          </a>
        </li>
        <li className='nav-item gradient-underline-menu-item'>
          <a className='nav-link' href='#localizare'>
            Localizare
          </a>
        </li>
        <li className='nav-item gradient-underline-menu-item'>
          <a className='nav-link' href='#footer'>
            Contact
          </a>
        </li>
        <li className='nav-item gradient-underline-menu-item'>
          <a className='nav-link booking-button' href='#rezervari'>
            Rezervă acum
          </a>
        </li>
      </ul>
    </nav>
  );
};
export default sideDrawer;
