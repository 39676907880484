import React from 'react';
import './IconsSection.scss';

interface IProps {
  alt: string;
  src?: string;
  title: string;
  description: string;
}

const SingleIcons = (props: IProps) => {
  return (
    <div className='iconsCard'>
      <div className='icon'>
        <img src={props.src} alt={props.alt} />
      </div>
      <>
        <div className='descriptionCard'>
          <div className='descriptionBorder'>
            <h4 className='iconTitle'>{props.title}</h4>
            <p className='iconDescription'>{props.description}</p>
          </div>
        </div>
      </>
    </div>
  );
};

export default SingleIcons;
