import $ from "jquery"

$(document).ready(function () {

  var navbar = $(".menu");
  var logo = $(".toolbar_logo")

  $(window).scroll(function () {
    if (window.pageYOffset >= 50) {
      navbar.addClass("sticky", 500);
      logo.addClass("sticky-logo", 500)
      
    } else {
      navbar.removeClass("sticky");
      logo.removeClass("sticky-logo");
    }
  })
})