import React from 'react';
import './reserveSection.scss';

interface IProps {
  btnStyle: string;
  alt: string;
  url: string;
  src: string;
  onClick: () => void;
}

const ReserveButton = (props: IProps) => {
  return (
    <>
      <a
        href={props.url}
        target='_blank'
        rel='noreferrer'
        onClick={props.onClick}
      >
        <img className={props.btnStyle} src={props.src} alt={props.alt} />
      </a>
    </>
  );
};

export default ReserveButton;
