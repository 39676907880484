import React from 'react';
import SideMenu from '../../Menu/responsivemenu/sidemenu/SideMenu';

import logoImage from '../../../../images/logo.png';

import '../ScrollHandler';
import './ToolBar.scss';

import images from '../../../../theme/images';

const toolbar = (props) => (
  <header className='toolbar menu'>
    <nav className='toolbar_navigator'>
      <div />
      <div className='toggle-btn'>
        <SideMenu click={props.drawerToggleClickHandler} />
      </div>
      <div className='toolbar_logo d-flex justify-content-end'>
        <a href='/'>
          <img className='stickyToolbarLogo' src={images.mainLogo} alt='logo' />
        </a>
      </div>

      <div className='toolbar_navigation-items'>
        <ul>
          <li className='nav-item gradient-underline-menu-item'>
            <a className='nav-link' href='#'>
              Acasă
            </a>
          </li>
          <li className='nav-item gradient-underline-menu-item'>
            <a className='nav-link' href='#despre-noi'>
              Despre Noi
            </a>
          </li>
          <li className='nav-item gradient-underline-menu-item'>
            <a className='nav-link' href='#galerie'>
              Galerie
            </a>
          </li>
          <li className='nav-item gradient-underline-menu-item'>
            <a className='nav-link' href='#recenzii'>
              Recenzii
            </a>
          </li>
          <li className='nav-item gradient-underline-menu-item'>
            <a className='nav-link' href='#oferte'>
              Oferte
            </a>
          </li>
          <li className='nav-item gradient-underline-menu-item'>
            <a className='nav-link' href='#rezervari'>
              Rezervări
            </a>
          </li>
          <li className='nav-item gradient-underline-menu-item'>
            <a className='nav-link' href='#localizare'>
              Localizare
            </a>
          </li>
          <li className='nav-item gradient-underline-menu-item'>
            <a className='nav-link' href='#footer'>
              Contact
            </a>
          </li>
          <li className='menuBtn'>
            <a className='nav-link booking-button' href='#rezervari'>
              Rezervă acum
            </a>
          </li>
        </ul>
      </div>
    </nav>
  </header>
);

export default toolbar;
