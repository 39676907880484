import React from 'react';

const SocialLinks = (props: any) => {
  return (
    <div className='row'>
      <div className='col-2 col-sm-2 col-md-2 col-lg-2 col-xl-1'>
        <a
          href='https://www.facebook.com/romanticstudiobv'
          target='_blank'
          rel='noreferrer'
        >
          <img className='sm-cion' src={props.fbIcon} alt='icon' />
        </a>
      </div>
      <div className='col-2 col-sm-2 col-md-2 col-lg-2 col-xl-1'>
        <a
          href='https://www.instagram.com/romanticstudiobrasov/'
          target='_blank'
          rel='noreferrer'
        >
          <img className='sm-cion' src={props.igIcon} alt='icon' />
        </a>
      </div>
      <div className='col-2 col-sm-2 col-md-2 col-lg-2 col-xl-1'>
        <a
          href='https://www.airbnb.com/rooms/51572270?source_impression_id=p3_1631888593_o2JXKhy7mLkC4Asn&guests=1&adults=1'
          target='_blank'
          rel='noreferrer'
        >
          <img className='sm-cion' src={props.airBnBIcon} alt='icon' />
        </a>
      </div>
      <div className='col-2 col-sm-2 col-md-2 col-lg-2 col-xl-1'>
        <a
          href='https://www.booking.com/hotel/ro/romantic-studio.ro.html'
          target='_blank'
          rel='noreferrer'
        >
          <img className='sm-cion' src={props.bookingBnBIcon} alt='icon' />
        </a>
      </div>
    </div>
  );
};

export default SocialLinks;
