import React from 'react';
import Copyrights from '../Copyrights/Copyrights';

import images from '../../../theme/images';
import './Footer.scss';
import SocialLinks from './SocialLinks';

function Footer() {
  return (
    <div id='footer' className='footer'>
      <div className='footer-wrapper'>
        <div className='row'>
          <div className='col-xs-12 col-sm-12 col-md-6 col-lg-4 p-white-font'>
            <h4 className='white-heading-shadow'>Romantic Studio Brașov</h4>
            <p>
              <span className='bold-white'>ADDRESS:</span>{' '}
              <a
                href='https://www.google.com/maps?q=Bulevardul+G%C4%83rii+20,+Bra%C8%99ov+500218,+Romania&rlz=1C1GCEA_enRO945RO945&um=1&ie=UTF-8&sa=X&ved=2ahUKEwjFmsTNkobzAhVDKuwKHX2oBIQQ_AUoAXoECAEQAw'
                target='_blank'
                rel='noreferrer'
                className='p-white-thin'
              >
                Bulevardul Gării 20, Brașov 500218, Romania
              </a>
            </p>
            <p>
              <span className='bold-white'>TEL:</span>{' '}
              <a href='tel:+0741-527-091' className='p-white-thin'>
                0741-527-091
              </a>
            </p>
            <p>
              <span className='bold-white'>EMAIL:</span>{' '}
              <a
                href='mailto: contact@romanticstudiobrasov.com'
                className='p-white-thin'
              >
                contact@romanticstudios.com
              </a>
            </p>

            <SocialLinks
              fbIcon={images.fbIcon}
              igIcon={images.igIcon}
              airBnBIcon={images.airBnBIcon}
              bookingBnBIcon={images.bookingBnBIcon}
            />
          </div>
          <div className='col-xs-12 col-sm-12 col-md-6 col-lg-4 d-flex justify-content-center logo-footer'>
            <a href='#'>
              <img className='footer-logo' src={images.mainLogo} alt='logo' />
            </a>
          </div>
          <div className='col-xs-12 col-sm-12 col-md-12 col-lg-4 footerMenu'>
            <h4 className='white-heading-shadow d-flex justify-content-center'>
              Meniu
            </h4>
            <div className='row p-white-font '>
              <div className='col-6 footer-menu-row'>
                <a className='footer-menu-item' href='#'>
                  Acasă
                </a>
                <a className='footer-menu-item' href='#despre-noi'>
                  Despre Noi
                </a>
                <a className='footer-menu-item' href='#galerie'>
                  Galerie
                </a>
                <a className='footer-menu-item' href='#recenzii'>
                  Recenzii
                </a>
              </div>
              <div className='col-6 footer-menu-row'>
                <a className='footer-menu-item' href='#rezervari'>
                  Rezervări
                </a>
                <a className='footer-menu-item' href='#localizare'>
                  Localizare
                </a>
                <a className='footer-menu-item' href='#footer'>
                  Contact
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Copyrights />
    </div>
  );
}

export default Footer;
