import React, { useState } from 'react';
import Carousel, { Dots, slidesToShowPlugin } from '@brainhubeu/react-carousel';
import SingleTestimonial from '../SingleTestimonial/SingleTestimonial';
import {
  Testimonials as testimonials,
  TestimonialsType,
} from '../../../constants/testimonials/Testimonials';

import ParagraphHeading from '../../paragraphHeading/ParagraphHeading';

import '@brainhubeu/react-carousel/lib/style.css';
import './Carousel.scss';
import strings from '../../../theme/strings';
import images from '../../../theme/images';

export default function TestimonialCarousel() {
  const [value, setValue] = useState(0);
  function onChange(value: any) {
    setValue(value);
  }

  return (
    <div id='recenzii'>
      <ParagraphHeading headingImg={images.testimonials} />

      <div className='carousel-wrapper-outer'>
        <Carousel
          value={value}
          onChange={onChange}
          plugins={[
            'arrows',
            'infinite',
            {
              resolve: slidesToShowPlugin,
              options: {
                numberOfSlides: 1,
              },
            },
          ]}
        >
          {testimonials.map((testimonials: any) => (
            <SingleTestimonial
              imageSrc={testimonials.imageSrc}
              name={testimonials.name}
              testimonial={testimonials.testimonial}
              starNumber={testimonials.starNumber}
            />
          ))}
        </Carousel>
      </div>
      <Dots value={value} onChange={onChange} number={6} />
    </div>
  );
}
