import React, { useState } from 'react';
import { Form, Row, Col, Button, Alert } from 'react-bootstrap';
import { Link, Navigate } from 'react-router-dom';
import ReactGA from 'react-ga';
import ThankYouPage from '../thankYouModal/ThankYouPage';

import './reserveSection.scss';
import './formSubmit';

import strings from '../../theme/strings';
import images from '../../theme/images';

const ContactForm = (props: any, context: any) => {
  const [name, setName] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [telephone, setTelephone] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [newsletterTick, setNewsletterTick] = useState<boolean>(true);
  const [redirect, setRedirect] = useState<boolean>(false);

  const validate = () => {
    let emailError = '';

    if (!email.includes('@')) {
      return (emailError = 'email invalid');
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const isValid = validate();
    if (isValid !== null) {
      setEmailError(emailError);
    }
    setName('');
    setMessage('');
    setTelephone('');
    setEmail('');
    setRedirect(true);
    ReactGA.event({
      category: 'User',
      action: 'Form button clicked',
    });
  };

  return (
    <div>
      <Form
        className='formWrapper'
        method='POST'
        action='contact.php'
        onSubmit={handleSubmit}
      >
        <Row>
          <Col xs={12} sm={12} md={12} lg={6}>
            <Form.Group>
              <Form.Label className='formLabels'>
                {strings.form.name}
              </Form.Label>
              <div className='formBorder'>
                <Form.Control
                  className='formInputs'
                  type='text'
                  name='name'
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
            </Form.Group>
            <Form.Group className='inputLine'>
              <Form.Label className='formLabels'>
                {strings.form.message}
              </Form.Label>
              <div className='formBorder'>
                <Form.Control
                  className='formTextArea'
                  as='textarea'
                  rows={7}
                  name='message'
                  value={message}
                  placeholder={strings.form.messagePlaceholder}
                  onChange={(e) => setMessage(e.target.value)}
                  required
                />
              </div>
            </Form.Group>
          </Col>
          <Col xs={12} sm={12} md={12} lg={6}>
            <Form.Group>
              <Form.Label className='formLabels'>
                {strings.form.telephone}
              </Form.Label>
              <div className='formBorder'>
                <Form.Control
                  className='formInputs'
                  type='tel'
                  name='telephone'
                  value={telephone}
                  onChange={(e) => setTelephone(e.target.value)}
                  required
                />
              </div>
            </Form.Group>
            <Form.Group className='inputLine'>
              <Form.Label className='formLabels'>
                {strings.form.email}
              </Form.Label>
              <div className='formBorder'>
                <Form.Control
                  className='formInputs'
                  type='email'
                  name='email'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                {emailError ? (
                  <div style={{ color: 'white' }}>{emailError}</div>
                ) : null}
              </div>
            </Form.Group>
            <div
              className='checkbox'
              onClick={() => setNewsletterTick((prevState) => !prevState)}
            >
              {newsletterTick && <img src={images.tickIcon} alt='tick' />}
            </div>
            <div>
              <p className='newsletterText'> {strings.form.checkbox}</p>
            </div>
            <Form.Check
              style={{ display: 'none' }}
              type='checkbox'
              value={newsletterTick.toString()}
              name='newsletter'
              checked={newsletterTick}
            />

            <Button className='formBtn' type='submit'>
              {strings.form.button}
            </Button>
          </Col>
        </Row>
      </Form>
      <div className='submitMessageStyle'>
        <Alert id='errorDiv' variant='danger'>
          {strings.form.error}
        </Alert>
      </div>
      {redirect && <Navigate to='/thankYou' />}
    </div>
  );
};

export default ContactForm;
