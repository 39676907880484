import pozaReviwer1 from "../../images/Testimonials/Stefaneanu.jpg";
import pozaReviwer2 from "../../images/Testimonials/Gabriel.jpg";
import pozaReviwer3 from "../../images/Testimonials/Roodolph.jpg";
import pozaReviwer4 from "../../images/Testimonials/Andreea.jpg";
import pozaReviwer5 from "../../images/Testimonials/Rochak.jpg";
import pozaReviwer6 from "../../images/Testimonials/Marina-Gabriela.jpg";

export type TestimonialsType = {name: string, testimonial: string, starNumber: number, imageSrc: string}[]

export const Testimonials: TestimonialsType = [
  {
    name: "Stefaneanu",
    testimonial:
      "Un loc foarte fain ce ofera o atmosfera intimă, relaxantă si foarte plăcută! Jacuzzi-ul e genial iar view-ul asupra orașului e superb. Recomand cu caldura studio-ul acesta!",
    starNumber: 5,
    imageSrc:  pozaReviwer1 ,
  },
  {
    name: "Gabriel",
    testimonial: "Excelent din toate punctele de vedere.",
    starNumber: 5,
    imageSrc: pozaReviwer2 ,
  },
  {
    name: "Roodolph",
    testimonial:
      "We booked late in the day. Alex replied immediately and was out of town at the time but agreed we could have a later check out. The room was amazing, great shower, and cute little kitchen, with everything you need. The views from the hot tub are so nice. The hot tub itself is incredible. Highly reccommend. Cheers alex!",
    starNumber: 5,
    imageSrc: pozaReviwer3,
  },
  {
    name: "Andreea",
    testimonial:
      "We had a very enjoyable time at the studio. The view of the mountains from the jacuzzi and the lights were amazing. The place is very modern and has everything you need if you want to spend a romantic weekend here. Close to shops and train station. Alex is an incredible host, ready to help us if needed. We will come again here for sure. 100% recomended!",
    starNumber: 5,
    imageSrc: pozaReviwer4,
  },
  {
    name: "Rochak",
    testimonial:
      "This condo is probably one of the best ones I have stayed in through AirBnB and I use AirBnB exclusively when I travel. The design is contemporary and the place immaculately clean. Alex is a super good host and extremely responsive. We had an unfortunate situation come up because of my mistake but Alex was calm and helpful in every way to find a resolution. If you’re gonna stay in Brasov, stay here.",
    starNumber: 5,
    imageSrc: pozaReviwer5,
  },
  {
    name: "Marina-Gabriela",
    testimonial:
      "Totul la superlativ, totally worth it. O experiența extraordinara: facilitați, view, curatenie, gazda. Recomand!",
    starNumber: 5,
    imageSrc: pozaReviwer6,
  }
];


