import React, { useEffect } from 'react';
import AboutSection from '../components/aboutSection/AboutSection';
import IconsSection from '../components/iconsSection/IconsSection';
import Offers from '../components/offers/Offers';
import ReserveSection from '../components/reserveSection/ReserveSection';
import TestimonialCarousel from '../components/reusableComponents/Carousel/Carousel';
import Footer from '../components/reusableComponents/Footer/Footer';
import Gallery from '../components/reusableComponents/gallery/Gallery';
import FirstSection from '../components/sections/firstSection/FirstSection';
import MapSection from '../features/map/MapSection';

const LandingPage = () => {
  return (
    <>
      <FirstSection />
      <AboutSection />
      <IconsSection />
      <Gallery />
      <TestimonialCarousel />
      <Offers />
      <ReserveSection />
      <MapSection />
      <Footer />
    </>
  );
};

export default LandingPage;
