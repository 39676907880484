const images = {
  panoramaIcon: require('../images/icons/panorama.png').default,
  houseIcon: require('../images/icons/house.png').default,
  bathIcon: require('../images/icons/bath.png').default,
  cartIcon: require('../images/icons/cart.svg').default,
  medicalIcon: require('../images/icons/medical.svg').default,
  starIcon: require('../images/icons/star.svg').default,
  treeIcon: require('../images/icons/tree.svg').default,
  pinMap: require('../images/pinMap.png').default,
  airbnbButton: require('../images/airbnb-button.png').default,
  bookingButton: require('../images/booking-button.png').default,
  about: require('../images/about-us.png').default,
  aboutUs: require('../images/about-heading.png').default,
  gallery: require('../images/gallery-heading.png').default,
  location: require('../images/location-heading.png').default,
  offers: require('../images/offers-heading.png').default,
  rezerve: require('../images/rezerve-heading.png').default,
  testimonials: require('../images/testimonials-heading.png').default,
  offer1: require('../images/offer1.jpg').default,
  offer2: require('../images/offer2.jpg').default,
  horizontalLine: require('../images/horizontalLine.png').default,
  tickIcon: require('../images/tick.png').default,
  galleryImages: {
    potraitPhoto1: require('../images/Gallery/detalii_apartament_40-min.jpg')
      .default,
    widePhoto11: require('../images/Gallery/detalii_apartament_44-min.jpg')
      .default,
    widePhoto21: require('../images/Gallery/image00001-min.jpeg').default,
    potraitPhoto2: require('../images/Gallery/detalii_apartament_48-min.jpg')
      .default,
    widePhoto12: require('../images/Gallery/Apartament Codruta_18-min.jpg')
      .default,
    widePhoto22: require('../images/Gallery/detalii_apartament_120-min.jpg')
      .default,
    potraitPhoto3: require('../images/Gallery/bedOverview-min.jpg').default,
    widePhoto13: require('../images/Gallery/detalii_apartament_124-min.jpg')
      .default,
    widePhoto23: require('../images/Gallery/handAndWineCloseup-min.jpg')
      .default,
    potraitPhoto4:
      require('../images/Gallery/Edi si A la apartament_115-min.jpg').default,
    widePhoto14:
      require('../images/Gallery/4thSlide/Edi si A la apartament_24-min.jpg')
        .default,
    widePhoto24:
      require('../images/Gallery/4thSlide/Apartament Codruta_33-min.jpg')
        .default,
  },
  christmasLogo: require('../images/christmas-logo.png').default,
  christmasHeader: require('../images/christmas-header.png').default,
  countdownHeader: require('../images/countdown-header.png').default,
  mainLogo: require('../images/logo.png').default,
  fbIcon: require('../images/SocialMediaIcons/jam_facebook-circle.png').default,
  igIcon: require('../images/SocialMediaIcons/akar-icons_instagram-fill.png')
    .default,
  airBnBIcon: require('../images/SocialMediaIcons/Vector (1).png').default,
  bookingBnBIcon: require('../images/SocialMediaIcons/Vector (2).png').default,
  airBnbIconDark: require('../images/airbnb-icon.svg').default,
  igIconDark: require('../images/instagram-icon.svg').default,
  fbIconDark: require('../images/facebook-icon.svg').default,
  bookingIconDark: require('../images/booking-icon.svg').default,
  valentinesOffer: require('../images/valetines-modal.png').default,
};

export default images;
