import React, { useState } from 'react';
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
} from '@react-google-maps/api';
import { places as data, placesType } from '../../data/placesMap';
import { mapStyle, mapContainerStyle } from './mapStyles';
import images from '../../theme/images';
import './mapSection.scss';

require('dotenv').config();

const center = {
  lat: 45.65337788209408,
  lng: 25.613376129827834,
};

const options = {
  styles: mapStyle,
  disableDefaultUI: true,
  zoomControl: true,
};

const position = {
  lat: 45.65968020449195,
  lng: 25.614134565605088,
};

const Map = () => {
  const [selectedPlace, setSelectedPlace] = useState<placesType | null>(null);
  const [apiKey, setApiKey] = useState<string>(
    process.env.REACT_APP_KEY ? process.env.REACT_APP_KEY : ''
  );

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: apiKey,
  });

  return isLoaded ? (
    <GoogleMap
      id='map'
      mapContainerStyle={mapContainerStyle}
      zoom={13}
      center={center}
      options={options}
    >
      <Marker
        icon={{
          url: images.pinMap,
          scaledSize: new window.google.maps.Size(80, 40),
        }}
        position={position}
      />

      {data.features.map((place) => (
        <Marker
          key={place.id}
          position={{ lat: place.coordinates[0], lng: place.coordinates[1] }}
          icon={{
            url: place.icon,
            scaledSize: new window.google.maps.Size(25, 25),
          }}
          onClick={() => {
            setSelectedPlace(place);
          }}
        />
      ))}

      {selectedPlace && (
        <InfoWindow
          position={{
            lat: selectedPlace.coordinates[0],
            lng: selectedPlace.coordinates[1],
          }}
          onCloseClick={() => {
            setSelectedPlace(null);
          }}
        >
          <div>
            <h6 className='placeTitle'>{selectedPlace.name}</h6>
            <hr />
            <p>{selectedPlace.category}</p>
            <p className='placeAddress'>{selectedPlace.address}</p>
          </div>
        </InfoWindow>
      )}
    </GoogleMap>
  ) : (
    <></>
  );
};

export default React.memo(Map);
