import React from 'react';
import { Row, Col } from 'react-bootstrap';
import SingleIcons from './SingleIcons';
import images from '../../theme/images';
import strings from '../../theme/strings';
import './IconsSection.scss';

const IconsSection = () => {
  return (
    <div className='iconsWrapper'>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={4}>
          <SingleIcons
            src={images.panoramaIcon}
            alt={strings.iconSection.panoramaAlt}
            title={strings.iconSection.panoramaHeading}
            description={strings.iconSection.panoramaDesc}
          />
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={4}>
          <SingleIcons
            src={images.houseIcon}
            alt={strings.iconSection.houseAlt}
            title={strings.iconSection.houseHeading}
            description={strings.iconSection.houseDesc}
          />
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={4}>
          <SingleIcons
            src={images.bathIcon}
            alt={strings.iconSection.bathAlt}
            title={strings.iconSection.bathHeading}
            description={strings.iconSection.bathDesc}
          />
        </Col>
      </Row>
    </div>
  );
};

export default IconsSection;
