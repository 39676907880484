import React from 'react';
import './Paragraph.scss';

interface IProps {
  text: string;
}

const Paragraph = (props: IProps) => {
  return (
    <>
      <p className='paragraph'>{props.text}</p>
    </>
  );
};

export default Paragraph;
