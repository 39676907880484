import portraitPhoto1 from '../../images/Gallery/detalii_apartament_40-min.jpg';
import widePhoto11 from '../../images/Gallery/detalii_apartament_44-min.jpg';
import widePhoto21 from '../../images/Gallery/image00001-min.jpeg';
import portraitPhoto2 from '../../images/Gallery/detalii_apartament_48-min.jpg';
import widePhoto12 from '../../images/Gallery/Apartament Codruta_18-min.jpg';
import widePhoto22 from '../../images/Gallery/detalii_apartament_120-min.jpg';
import portraitPhoto3 from '../../images/Gallery/bedOverview-min.jpg';
import widePhoto13 from '../../images/Gallery/detalii_apartament_124-min.jpg';
import widePhoto23 from '../../images/Gallery/handAndWineCloseup-min.jpg';
import portraitPhoto4 from '../../images/Gallery/Edi si A la apartament_115-min.jpg';
import widePhoto14 from '../../images/Gallery/4thSlide/Edi si A la apartament_24-min.jpg';
import widePhoto24 from '../../images/Gallery/4thSlide/Apartament Codruta_33-min.jpg';
import portraitPhoto5 from '../../images/Gallery/details1.jpg';
import portraitPhoto6 from '../../images/Gallery/details2.jpg';
import portraitPhoto7 from '../../images/Gallery/details3.jpg';
import portraitPhoto8 from '../../images/Gallery/details4.jpg';

export type GalleryPhotosType = {
  portraitPhoto: string;
  widePhoto1: string;
  widePhoto2: string;
  portraitPhoto2: string;
}[];

export const GalleryPhotos: GalleryPhotosType = [
  {
    portraitPhoto: portraitPhoto1,
    widePhoto1: widePhoto11,
    widePhoto2: widePhoto21,
    portraitPhoto2: portraitPhoto2,
  },
  {
    portraitPhoto: portraitPhoto3,
    widePhoto1: widePhoto12,
    widePhoto2: widePhoto22,
    portraitPhoto2: portraitPhoto4,
  },
  {
    portraitPhoto: portraitPhoto5,
    widePhoto1: widePhoto13,
    widePhoto2: widePhoto23,
    portraitPhoto2: portraitPhoto6,
  },
  {
    portraitPhoto: portraitPhoto7,
    widePhoto1: widePhoto14,
    widePhoto2: widePhoto24,
    portraitPhoto2: portraitPhoto8,
  },
];
