import axios from 'axios';

const conversionsApi = () => {
  const pixelApiKey = process.env.REACT_APP_FB_PIXEL;
  const accessToken = process.env.REACT_APP_ACCESS_TOKEN;
  axios.post(
    `https://graph.facebook.com/v12.0/${pixelApiKey}/events?access_token=${accessToken}`,
    {
      data: [
        {
          event_name: 'Pageview',
          event_time: Date.now(),
          action_source: 'website',
          event_source_url: null,
          user_data: {
            em: [null],
            ph: [null],
            ge: [null],
            fbp: null,
            country: [null],
            db: [null],
          },
        },
      ],
    }
  );
};

export default conversionsApi;
