import React from 'react';
import { Row, Col } from 'react-bootstrap';
import './GalleryCard.scss';

type Props = {
  portraitPhoto: string;
  widePhoto1: string;
  widePhoto2: string;
  portraitPhoto2: string;
};

const imageAlt = 'brasov studio apartments';

function GalleryCard({
  portraitPhoto,
  widePhoto1,
  widePhoto2,
  portraitPhoto2,
}: Props) {
  return (
    <div className='gallery-card'>
      <Row>
        <Col>
          <img
            className='gallery-portrait-photo'
            src={portraitPhoto}
            alt={imageAlt}
          />
        </Col>
        <Col>
          <img
            className='gallery-wide-photo wide-pic-1'
            src={widePhoto1}
            alt={imageAlt}
          />
          <img
            className='gallery-wide-photo wide-pic-2'
            src={widePhoto2}
            alt={imageAlt}
          />
        </Col>
        <Col>
          <img
            className=' gallery-portrait-photo portrait-photo-right'
            src={portraitPhoto2}
            alt={imageAlt}
          />
        </Col>
      </Row>
    </div>
  );
}

export default GalleryCard;
